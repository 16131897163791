body {
  background-color: #f3f5f7;
  width: 100%;
  height: 100%;
}



.material-symbols-rounded {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48;
  font-size: 17px;
  padding-right: 5px;
  color: rgb(49, 49, 49);
  }
  .material-symbols-outlined {
    color: rgb(49, 49, 49);
  }
  
  
  h2 {
    text-align: left;
    color: rgb(49, 49, 49);
    font-family: 'PT Sans', sans-serif;
    margin: 30px 0px 30px 0px;
  }
  
  h3 {
    
    text-align: left;
    color: rgb(49, 49, 49);
    font-family: 'PT Sans', sans-serif;
    clear: both;
    margin: 30px 0px 10px 0px;
  }
  li {
    font-family: 'PT Sans', sans-serif;
    list-style: none;
    padding-top: 10px;
    color: rgb(49, 49, 49);
    cursor: pointer;
  }
  p {
    color: rgb(49, 49, 49);
  }
  a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
  }
  
