.recent{
    width:275px;
    height: 180px;
    background-color: #ffffff;
    border-radius: 5px;
    float: left;
    margin: 0px 20px 20px 0px;
    font-family: 'PT Sans', sans-serif;
    cursor: pointer; 
    position:relative;
    text-decoration: none;
    color: inherit;
    border: 0.5px solid #e4e4e4;
    
}
#recent_section {
    grid-column: 1/4;
    grid-row: 1/2;
    margin: 0;
}
.project1_link {      
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-decoration: none;
    color: rgb(49, 49, 49);
     
}

.project1_link p {
    padding: 15px;
    padding-bottom: 10px;
    font-weight: 700;
    font-size: 18px; 
    height: 25px;
}

.project_description  {
    font-size: 13px;
    padding: 15px;
    padding-top: 15px;
    padding-bottom: 0px;
    height: 70px;
    color: #888;
}
.recent_description span {
    float: left;
    color: #888;
    padding-left: 15px;
    padding-top: 14px;
    font-size: 15px;
}
.recent_description {
    width: auto; 
    height: 50px;
    border: 0.5px solid #e4e4e4;
    border-style: solid none none none;
    
}
.recent_description p {
    float:left;
    font-size: 12px;
    padding-top: 14px;
}
.recent_announcements {
    color:#888;
}

.recent_description .date  {
    float: left;
    margin: 0px;
    margin-left: 10px;
    margin-top: 8px;
    width: 60px;
    border-radius: 5px;
    font-size: 12px;
    padding: 0px 0px 3px 3px;
        
    
}
.recent_description .date span {
    padding-left: 0px;
    padding-top: 2px;
    font-size: 12px;
}
.recent_description .date p  {
    padding-top: 0px;
    padding-top: 6px;
    font-size: 12px;
}
.recent a {
    width: 100%;
    height: 100%;
    
}
.recent .priority {
    padding-left: 10px;
    float: left;
    margin: 0px;
}

#display_projects {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    width: 80%;
    grid-column: 1/3;
    grid-row: 2/3;
    margin: 0px;
    
}
