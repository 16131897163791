#content {
    grid-column: 2/3;
    grid-row: 1/2;
    margin-top:30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: max-content 1fr;
    margin-left: 270px;
    margin-top: 51px;
    grid-gap: 10px;
    width: 900px;
    margin-right: 150px;
    float: left;
    
}