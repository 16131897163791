body {
    margin: 0;
  }
  
  .app {
    padding: 50px;
    height: 100vh;
    background-size: cover;   
}
h1 { 
  color: rgb(9, 125, 33);
  span {
      display: block;
      color: darken(rgba(20, 108, 45, 0.983), 20%);
      font-size: 14px;
      font-style: italic;
      height: 14px;
  }
}

.note-form {
  display: flex;
  flex-direction: column;
  width: 200px;

  textarea {
      font-family: Arial, Helvetica, sans-serif;
      border: 1px solid lightgrey;
      min-width: 100%;
      max-width: 100%;
      height: 100px;
      padding: 10px;
      outline: none;
  }

  button {
      width: 100%;
      background-color: #fff27f;
      cursor: pointer;
      padding: 10px; 
      border: 0;
      outline: none;
      &:hover {
          background-color: tomato;
          color: white;
      }
  }
}

.note {
  position: absolute;
  cursor: pointer;
  background-image: url('assets/post-it-note.png');
  background-size: cover;
  top: 100px;
  left: 300px;
  height: 200px;
  width: 200px;
  padding: 20px;
  

  .text {
      font-family: Arial, Helvetica, sans-serif;
      height: 100%;
      width: 100%;
      white-space: pre-wrap;
      overflow-y: hidden;
      padding-top: 20px;
      color: rgb(49, 49, 49);
  }

  .close {
      width: 15px;
      height: 15px;
      position: absolute;
      right: 15px;
      transition: transform .3s ease;

      &:hover {
          transform: scale(1.6);
      }
  }
}

