#overview {
    background-color: #ffffff;
    height: 100vh;
    padding-top: 40px;
    padding-right: 40px;
    position: fixed;
    margin-top: 51px;
    z-index: 1;
}
li .project_id {
    display: none;
}
#display_tree {
    border: 0.5px solid #e4e4e4;
    border-style: solid none none none; 
    width: 119%;
    margin-top: 30px;
    
}

h3 {
    margin-top:10px
}