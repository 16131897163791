* {
  margin: 0;
}

/* ------------------------ Menu ------------------------ */
.project p {
  line-height: 60px;
  color: rgb(49, 49, 49);
  padding-left: 20px;
  padding-top: 0px;
  font-family: 'PT Sans', sans-serif;
}
.project {
  background-color: #ffffff;
  border-radius: 5px;
  border: 0.5px solid #e4e4e4;

}


* {
  margin: 0;
}
